<script lang="ts" setup>
import {
  ContainerOutlined,
  DollarOutlined,
  FileSyncOutlined,
  HomeOutlined,
  IdcardOutlined,
  PoweroffOutlined,
  TagOutlined,
  BarChartOutlined,
} from '@ant-design/icons-vue'
import { UserOutlined, ShoppingOutlined, GoldOutlined, WalletOutlined, CreditCardOutlined } from '@ant-design/icons-vue';
import { notification } from 'ant-design-vue'

import { useAclStore } from '../store/acl'
import { UserModel } from '~/models/user'
import { getRealm } from '~/realm/setup'
import { useAgentBeautician } from '~/composable/agent';
import Nav from '~/components/layouts/nav.vue'
import Footer from '~/components/layouts/footer.vue'
// import { currencies } from '~/shared/site';
const { isOutletOwner, isAgent, isGuest } = useAgentBeautician()

const $acl = useAclStore()
const $router = useRouter()
const $alert = useAlertStore()
const userModel = new UserModel()
const realm = getRealm()
const $route = useRoute()

const $nav = useNavStore()
// useHead({
//   title: computed(() => $nav.title ? `${$nav.title} - Cel-Elements` : 'Cel-Elements'),
// })

const computedTitle = computed(() => $nav.title || $route.meta.title ? `${$nav.title || $route.meta.title} - Cel-Elements` : 'Cel-Elements')
useHead({
  title: computedTitle,
  meta: [
    { property: 'og:title', content: computedTitle }
  ]
})

const expandedMenu = ref(['myAccount', 'purchase'])
//'myAccount', 'profile'
const selectedKeys = ref([])

onMounted(() => {
  $acl.checkSession()
  if (!$acl.currentUser || !$acl.currentUser.uid) {
    $router.push('/login')
  }
})

function onCollapse(collapsed, type) {
  console.log(collapsed, type)
}

function onBreakpoint(broken) {
  console.log(broken)
}

watch(
  () => ({ isOutletOwner, isAgent, isGuest }),
  () => {
    console.log('watch', { isOutletOwner, isAgent, isGuest })
  }
)


let lastError = ''
let lastSuccess = ''

watch(() => $alert?.error, (msg: string) => {
  if ($alert.error && lastError !== $alert.error) {
    notification.open({
      message: 'Error',
      description:
        $alert.error,
    })
  }
  lastError = $alert.error
})

watch(() => $alert.success, (msg: string) => {
  if ($alert.success && lastSuccess !== $alert.success) {
    notification.open({
      message: 'Success',
      description:
        $alert.success,
    })
  }
  lastSuccess = $alert.success
})

interface MenuItem {
  name: string;
  path?: string;
  icon: any;

  childs?: MenuItem[];
}

const menuItems = computed(() => {
  return [
    {
      name: 'My Account',
      icon: UserOutlined,
      childs: [
        ...(isOutletOwner.value || isAgent.value ?
          [{
            name: 'Report',
            path: '/account/salesReports',
            icon: BarChartOutlined,
          }] : []),
        { name: 'My profile', path: '/account/profile', icon: UserOutlined },
        { name: 'Addresses', path: '/account/addresses', icon: HomeOutlined },
        { name: 'My Order(s)', path: '/account/orderHistories', icon: ShoppingOutlined },]
    },

    {
      name: 'Points & Wallet',
      icon: ShoppingOutlined,
      childs: [
        { name: 'Voucher', path: '/account/vouchers', icon: UserOutlined },
        { name: 'Loyalty Points', path: '/account/loyaltyPoints', icon: GoldOutlined },
        { name: 'Topup Wallet', path: '/account/cashWallets', icon: WalletOutlined },
        { name: 'Commission Wallet', path: '/account/commWallets', icon: DollarOutlined },
        { name: 'Cashback Wallet', path: '/account/cashbackWallets', icon: CreditCardOutlined },
      ]
    },
  ]
})

const selectMenu = (item: MenuItem) => {
  selectedKeys.value = [item.path]
  $nav.title = item.name
  $router.push(item.path)
}
</script>

<template>
  <a-layout theme="light" class=" max-w-full min-h-screen mx-auto">
    <div class="bg-[#8DB600] w-full h-[186px] absolute top-0 left-0"> </div>
    <Nav />
    <div class="w-[1200px] flex gap-1 bg-gray-100 mx-auto ">
      <div class="bg-white w-[260px] float-left min-h-screen h-full">
        <div class="menu mb-2">
          <div v-for="(item, index) in menuItems" :key="index">
            <div v-if="item.childs" class="font-medium p-4">{{ item.name }}</div>
            <template v-if="item.childs">
              <div v-for="child in item.childs" :key="child.path"
                class="pl-4 px-2 py-2 flex items-center gap-2 cursor-pointer" @click="selectMenu(child)">
                <component :is="child.icon" />
                <span>{{ child.name }}</span>
              </div>
            </template>
            <div v-else class="p-2 flex items-center gap-2 cursor-pointer" @click="selectMenu(item)">
              <component :is="item.icon" />
              <span>{{ item.name }}</span>
            </div>
          </div>
        </div>
        <a-menu mode="inline" class="mt-2" :inline-indent="12" @collapse="onCollapse" v-if="false">
          <a-sub-menu key="myAccount" title="My Account">
            <a-menu-item key="profile" @click="$router.push('/account/profile')">
              <IdcardOutlined />
              <span class="nav-text">My profile</span>
            </a-menu-item>
            <a-menu-item key="addresses" @click="$router.push('/account/addresses')">
              <HomeOutlined />
              <span class="nav-text">Addresses</span>
            </a-menu-item>
          </a-sub-menu>
          <a-sub-menu key="purchase" title="Purchase">
            <a-menu-item v-if="isOutletOwner || isAgent || isGuest" key="salesReport"
              @click="$router.push('/account/salesReports')">
              <ContainerOutlined />
              <span class="nav-text">Sales Report</span>
            </a-menu-item>

            <a-menu-item key="orderHistories" @click="$router.push('/account/orderHistories')">
              <FileSyncOutlined />
              <span class="nav-text">My Order(s)</span>
            </a-menu-item>

            <!-- <a-menu-item
            key="merchandiseReturns"
            @click="$router.push('/account/merchandiseReturns')"
          >
            <CloseSquareOutlined />
            <span class="nav-text">Merchandise Return</span>
          </a-menu-item> -->
          </a-sub-menu>
          <!-- <a-menu-item key="claimVouchers" @click="$router.push('/account/claimVouchers')">
          <GiftOutlined />
          <span class="nav-text">Claim Voucher</span>
        </a-menu-item> -->
          <!-- <a-menu-item key="creditSlips" @click="$router.push('/account/creditSlips')">
          <BookOutlined />
          <span class="nav-text">Credit Slips</span>
        </a-menu-item> -->
          <a-menu-item key="vouchers" @click="$router.push('/account/vouchers')">
            <TagOutlined />
            <span class="nav-text">Voucher</span>
          </a-menu-item>
          <!-- <a-menu-item key="favoriteProducts" @click="$router.push('/account/favoriteProducts')">
          <HeartOutlined />
          <span class="nav-text">Favorite Products</span>
        </a-menu-item> -->
          <!-- <a-menu-item key="alerts" @click="$router.push('/account/alerts')">
          <BellOutlined />
          <span class="nav-text">Alerts</span>
        </a-menu-item> -->
          <a-menu-item key="loyaltyPoints" @click="$router.push('/account/loyaltyPoints')">
            <DollarOutlined />
            <span class="nav-text">Loyalty Points</span>
          </a-menu-item>

          <a-menu-item key="ewallet" @click="$router.push('/account/cashWallets')">
            <DollarOutlined />
            <span class="nav-text">Topup Wallet</span>
          </a-menu-item>

          <a-menu-item v-if="isOutletOwner || isAgent || isGuest" key="commissionWallet"
            @click="$router.push('/account/commWallets')">
            <DollarOutlined />
            <span class="nav-text">Commission Wallet</span>
          </a-menu-item>

          <a-menu-item key="cashbackWallet" @click="$router.push('/account/cashbackWallets')">
            <DollarOutlined />
            <span class="nav-text">Cashbach Wallet</span>
          </a-menu-item>
        </a-menu>

        <a-button type="primary" block style @click="$router.push('/logout')">
          Log out
          <PoweroffOutlined />
        </a-button>
      </div>
      <div class="w-[1200px] mx-auto min-h-screen px-2 pb-6">

        <a-alert v-if="$alert.error" :message="$alert.error" type="error" class="fixed top-0 z-10 w-full layout-alert"
          closable @close="$alert.clearError" />
        <a-alert v-if="$alert.success" :message="$alert.success" type="success"
          class="fixed top-0 z-10 w-full layout-alert" closable @close="$alert.clearSuccess" />
        <div v-if="$alert.error" class="h-10"></div>
        <slot />
      </div>
    </div>

    <Footer />
  </a-layout>
</template>

<style lang="scss">
@media print {
  .ALayoutSlider {
    display: none;
  }
}
</style>
